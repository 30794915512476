import React from 'react';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';
import { AstronautLoader } from '../pages/Login';

export const RestrictedRoute = ({ children }) => {
	const { userDetails, loading } = useSelector((state) => state.user);

	if (!loading) {
		if (userDetails.role === 1 || userDetails.role === 0) {
			return children;
		} else {
			return <Navigate to="/dashboard" />;
		}
	}
	return (
		<div style={{ display: 'grid', justifyItems: 'center' }}>
			{AstronautLoader}
		</div>
	);
};
