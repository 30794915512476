import { configureStore } from '@reduxjs/toolkit';
import selectedBuilding from './redux/selectedBuilding/SelectedBuildingSlice';
import user from './redux/user/UserSlice';
import auth from './redux/auth/AuthSlice';
import navbarToggle from './redux/navbar/NavbarToggleSlice';
import buildings from './redux/buildings/BuildingsSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
	key: 'selectedBuilding',
	storage,
};

const authPersistConfig = {
	key: 'auth',
	storage,
};

const buildingsPersistConfig = {
	key: 'buildings',
	storage,
};

const persistedReducer = persistReducer(persistConfig, selectedBuilding);
const persistedAuth = persistReducer(authPersistConfig, auth);
const persistedBuildings = persistReducer(buildingsPersistConfig, buildings);
export const store = configureStore({
	reducer: {
		user,
		auth: persistedAuth,
		selectedBuilding: persistedReducer,
		navbarToggle,
		buildings: persistedBuildings,
	},
	middleware: [thunk],
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
