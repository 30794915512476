import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	accessToken: '', // The initial state for the access token
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
		clearAccessToken: (state, action) => {
			state.accessToken = '';
		},
	},
});

export const { setAccessToken, clearAccessToken } = authSlice.actions;

export default authSlice.reducer;
